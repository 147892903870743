var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "two-solar-navigation__item",
      attrs: { to: _vm.to, exact: "", tag: "li" },
    },
    [
      _c(
        "span",
        { staticClass: "h-full w-full py-5 px-3", on: { click: _vm.click } },
        [
          _c("HeaderText", { staticClass: "truncate" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }