var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "missing-data-container" }, [
    _c(
      "div",
      { staticClass: "missing-data-container__image-wrap" },
      [
        !_vm.hiddenImage
          ? _c("error-image", { staticClass: "w-full" })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "missing-data-container__content-wrap",
        class: { "-mt-10": _vm.hiddenImage },
      },
      [
        _c(
          "div",
          { staticClass: "missing-data-container__title" },
          [_vm._t("title")],
          2
        ),
        _c(
          "div",
          { staticClass: "missing-data-container__content" },
          [_vm._t("content")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }