var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-page" },
    [
      _c(
        "missing-data-container",
        [
          _c("heading2", { attrs: { slot: "title" }, slot: "title" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$trans("portal.messages.error_page.title.0"))),
            ]),
            _c("br"),
            _c("strong", [
              _vm._v(_vm._s(_vm.$trans("portal.messages.error_page.title.1"))),
            ]),
          ]),
          _c("heading3", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v(" " + _vm._s(_vm.pageText) + " "),
            _vm.template
              ? _c("div", [
                  _vm.settings.email
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-center justify-center pt-5",
                        },
                        [
                          _c("letter-icon", { staticClass: "w-4 h-4 mr-2" }),
                          _c("heading3", [
                            _c("a", {
                              attrs: {
                                href: "mailto:" + _vm.settings.email,
                                target: "_blank",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.settings.email),
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.settings.phone
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-center justify-center pt-5",
                        },
                        [
                          _c("phone-icon", { staticClass: "w-4 h-3 mr-2" }),
                          _c("heading3", [
                            _c("a", {
                              attrs: { href: "tel:" + _vm.settings.phone },
                              domProps: {
                                textContent: _vm._s(_vm.settings.phone),
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }