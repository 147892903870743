var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "logo-wrap logo-wrap__secondary", style: _vm.cssVariables },
    [
      _c("el-image", {
        staticClass: "logo",
        attrs: { src: _vm.url, fit: "contain" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }