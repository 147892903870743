var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "fill-current",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 12" },
    },
    [
      _c("path", {
        attrs: {
          d: "M8 5.32L14.973.666H1.03L8 5.319zm8 6.013V1.497L8 6.843 0 1.505v9.828h16z",
          "clip-rule": "evenodd",
        },
      }),
      _c(
        "mask",
        {
          attrs: {
            id: "a",
            width: "16",
            height: "12",
            x: "0",
            y: "0",
            maskUnits: "userSpaceOnUse",
          },
        },
        [
          _c("path", {
            attrs: {
              fill: "#fff",
              "fill-rule": "evenodd",
              d: "M8 5.32L14.973.666H1.03L8 5.319zm8 6.013V1.497L8 6.843 0 1.505v9.828h16z",
              "clip-rule": "evenodd",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }