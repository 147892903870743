var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("router-view-container", [
    _c(
      "main",
      {
        ref: "main",
        staticClass: "tablet:mt-0 body-content",
        class:
          `${_vm.$screen.mobile.vendor} ${_vm.$screen.mobile.orientation}`.trim(),
      },
      [_c("pages-sidebar"), _c("router-view", { key: _vm.$route.path })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }