import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=14ade4d8&scoped=true&"
import script from "./Icon.vue?vue&type=script&lang=js&"
export * from "./Icon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ade4d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/2solar-software/portal-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14ade4d8')) {
      api.createRecord('14ade4d8', component.options)
    } else {
      api.reload('14ade4d8', component.options)
    }
    module.hot.accept("./Icon.vue?vue&type=template&id=14ade4d8&scoped=true&", function () {
      api.rerender('14ade4d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/core/components/icons/Icon.vue"
export default component.exports