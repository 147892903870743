var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "header-menu__item",
      style: _vm.cssVariables,
      attrs: { to: _vm.to, "exact-active-class": "header-menu__item--active" },
    },
    [
      _c("HeaderText", { staticClass: "header-menu__item-title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }