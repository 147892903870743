var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "absolute w-full h-full",
    },
    [
      _c(
        "el-drawer",
        {
          staticClass: "pages-sidebar",
          attrs: {
            direction: "ltr",
            visible: _vm.isVisible,
            modal: false,
            "with-header": false,
            "before-close": _vm.handleBeforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pages-sidebar__header flex items-center px-6",
              on: {
                click: function ($event) {
                  return _vm.toggleSidebar({ name: "PagesSidebar" })
                },
              },
            },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "portal.home" } } },
                [_c("secondary-logo", { staticClass: "h-8" })],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "scroll-lock",
                  rawName: "v-scroll-lock",
                  value: _vm.isVisible,
                  expression: "isVisible",
                },
              ],
              staticClass: "pages-sidebar__content",
              class:
                `${_vm.$screen.mobile.vendor} ${_vm.$screen.mobile.orientation}`.trim(),
            },
            [
              _c("navigation", {
                attrs: { items: _vm.menuPages },
                on: {
                  "navigation-change": function ($event) {
                    return _vm.toggleSidebar({ name: "PagesSidebar" })
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pages-sidebar__footer" }, [
            _c("div", { staticClass: "pages-sidebar__footer-content" }, [
              _vm.settings.email || _vm.settings.phone
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col justify-center items-center pt-2 pb-3 flex-grow-0 rounded-lg bg-gray-100 pages-sidebar__footer-contacts",
                    },
                    [
                      _vm.settings.email
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center text-gray-600 pages-sidebar__footer-item",
                            },
                            [
                              _c("letter-icon", {
                                staticClass: "w-4 h-4 mr-2",
                              }),
                              _vm._v(" " + _vm._s(_vm.settings.email) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.settings.phone
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center text-gray-600 pt-3 pages-sidebar__footer-item",
                            },
                            [
                              _c("phone-icon", { staticClass: "w-4 h-4 mr-2" }),
                              _c("phone-formatter", {
                                attrs: { value: _vm.settings.phone },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 flex justify-center color-blue-500-10 pages-sidebar__footer-copyright",
                },
                [_vm._v(" © 2021 2Solar Software ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }