import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=0d82a67d&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d82a67d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/2solar-software/portal-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d82a67d')) {
      api.createRecord('0d82a67d', component.options)
    } else {
      api.reload('0d82a67d', component.options)
    }
    module.hot.accept("./Navigation.vue?vue&type=template&id=0d82a67d&scoped=true&", function () {
      api.rerender('0d82a67d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/containers/portal/components/sidebars/navigation/Navigation.vue"
export default component.exports