var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "fill-current",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 12 12" },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.784 10.396c-.37 1.126-1.822 1.688-2.858 1.594-1.416-.128-2.957-.88-4.127-1.706C3.08 9.068 1.47 7.187.531 5.19-.132 3.781-.28 2.046.706.761 1.07.286 1.465.032 2.058.002c.822-.04.937.43 1.22 1.164.21.548.49 1.107.648 1.675.293 1.06-.733 1.104-.863 1.97-.08.546.582 1.28.881 1.669A8.084 8.084 0 0 0 6.03 8.402c.456.287 1.19.805 1.712.52.804-.44.728-1.795 1.85-1.337.582.237 1.145.579 1.7.878.859.461.819.94.492 1.933-.245.743.245-.742 0 0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }