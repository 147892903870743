var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "w-full h-screen flex items-center justify-center text-center",
      },
      [
        _c("div", [
          _c("div", { staticClass: "lds-dual-ring" }),
          _c("h1", { staticClass: "font-lg" }, [_vm._v(" Loading portal... ")]),
          _c("p", [_vm._v("Stand by...")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }