var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c(
        "el-switch",
        _vm._g(
          _vm._b(
            {
              staticClass: "switcher",
              style: _vm.cssVariables,
              attrs: { value: _vm.value },
              on: { input: _vm.onInput },
            },
            "el-switch",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c("heading3", { staticClass: "uppercase pl-3" }, [
        _vm._v(" " + _vm._s(_vm.stateText) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }