var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "cookies-modal fixed bottom-0 bg-white h-full tablet:h-auto w-full z-30 px-5 laptop:px-15 py-4 laptop:py-9.75 bg-black-70 tablet:bg-white flex tablet:block items-center z-9999",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tablet:flex bg-white tablet:bg-transparent py-5 tablet:py-0 rounded-4",
        },
        [
          _c(
            "div",
            [
              _c(
                "heading4",
                {
                  staticClass: "flex mb-3 justify-center tablet:justify-start",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$trans(
                          "portal.messages.personal_settings.cookies_policy_title"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c("page-text", { staticClass: "mr-5 px-3 tablet:px-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$trans(
                        "portal.messages.personal_settings.cookie_policy_description"
                      )
                    ) +
                    " "
                ),
              ]),
              _vm.isManageCookies
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col tablet:flex-row mt-6 pl-9 tablet:pl-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("Switcher", {
                            attrs: {
                              "enabled-label-text": "",
                              "disabled-label-text": "",
                            },
                            model: {
                              value: _vm.cookies.analytical,
                              callback: function ($$v) {
                                _vm.$set(_vm.cookies, "analytical", $$v)
                              },
                              expression: "cookies.analytical",
                            },
                          }),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$trans("core.labels.analytical"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "laptop:ml-12 flex tablet:block flex-row-reverse px-3 tablet:px-0 mt-9 tablet:mt-0 laptop:min-w-250 flex-wrap",
            },
            [
              _c(
                "button-primary",
                {
                  staticClass: "w-full uppercase",
                  attrs: {
                    loading: _vm.isProcessing,
                    disabled: _vm.isProcessing,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.acceptCookies()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.acceptCookiesLabel) + " ")]
              ),
              _c(
                "button-secondary",
                {
                  staticClass:
                    "w-full manage-cookies uppercase tablet:mt-5 mt-3",
                  attrs: { loading: _vm.isProcessingFlags.rejectAll },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.manageCookies.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.manageCookiesLabel) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }