var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "two-solar-navigation" },
    _vm._l(_vm.items, function (item) {
      return _c("navigation-item", {
        key: item.id,
        attrs: { value: item },
        on: {
          "navigation-item-select": function ($event) {
            return _vm.click(item)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }