var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isProcessing
        ? _c("template-loader")
        : _vm.isError
        ? _c("error-page", { attrs: { error: _vm.error } })
        : [
            _c("router-view", { attrs: { name: "header" } }),
            _c("body-content"),
            _c("router-view", { attrs: { name: "footer" } }),
          ],
      _c("portal-target", { attrs: { name: "modal-outlet" } }),
      _vm.isModalCookies ? _c("CookiesModal") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }